/** @jsx jsx */
import { jsx } from "theme-ui"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Image = props => (
  <StaticQuery
    query={graphql`
      query {
        images: allFile(filter: { fields: { PartnerImage: { eq: "true" } } }) {
          edges {
            node {
              id
              fields {
                parentId
                title
                slug
                uri
              }
              relativePath
              name
              childImageSharp {
                # sizes(maxWidth: 600) {
                #   ...GatsbyImageSharpSizes
                # }
                fluid(maxWidth: 400) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
      const image = data.images.edges.find(n => {
        return n.node.relativePath.includes(props.filename)
      })
      if (!image) {
        return null
      }

      // const imageSizes = image.node.childImageSharp.sizes
      const imageSizes = image.node.childImageSharp.fluid
      return <Img alt={props.alt} fluid={imageSizes} />
    }}
  />
)

export default Image
