/** @jsx jsx */
import { useStaticQuery, graphql } from "gatsby"
import GatsbyImage from "../components/GatsbyImage"
import { jsx } from "theme-ui"
import { motion } from "framer-motion"
import { TheLayout, FindSpace, LinkWrapper } from "x"
import { Box, Card, Grid, Heading } from "@theme-ui/components"

const TheContainer = motion.custom(Grid)
const ItemBox = motion.custom(Card)

const OfficeGuysPage = () => {
  const data = useStaticQuery(
    graphql`
      query {
        craft {
          entries(section: [partners], site: "osg") {
            ... on Craft_Partners {
              id
              title
              uri
              slug
              postDate
              body {
                totalPages
                content
              }
              headshot {
                id
                url
                filename
                focalPoint {
                  x
                  y
                }
              }
            }
          }
        }
      }
    `
  )

  const partners = data.craft.entries

  const thelist = {
    hidden: {
      opacity: 1,
      scale: 0,
    },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        when: "beforeChildren",
        staggerChildren: 0.1,
      },
    },
  }

  const theitem = {
    hidden: { y: 20, opacity: 0 },
    visible: { y: 0, opacity: 1 },
  }

  return (
    <TheLayout seoTitle="The Office Guys" sx={{ bg: "background" }}>
      <TheContainer
        initial="hidden"
        animate="visible"
        variants={thelist}
        gap={4}
        columns={[2, null, 4]}
      >
        {partners.map(item => (
          <ItemBox
            key={item.id}
            variants={theitem}
            variant="bio"
            whileHover={{ scale: 1.065 }}
            transition={{ duration: 0.365 }}
          >
            <GatsbyImage
              filename={item.headshot[0].filename}
              alt={item.title}
            />

            <Box
              p={3}
              sx={{
                textAlign: "center",
              }}
            >
              <Heading variant="minicaps">{item.title}</Heading>
            </Box>

            <LinkWrapper to={`/${item.uri}/`}>
              Read about {item.title}
            </LinkWrapper>
          </ItemBox>
        ))}
      </TheContainer>
      <FindSpace />
    </TheLayout>
  )
}

export default OfficeGuysPage
